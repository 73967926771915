<template>
  <Layout>
    <access-keeper entity="PROVIDER">
      <provider-header @newProviderAdded="handleNewProviderAdded" />
      <provider-table ref="providerTable" />
    </access-keeper>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import ProviderHeader from '@components/provider/provider-header';
import ProviderTable from '@components/provider/provider-table';
import AccessKeeper from '@components/shared/region-guard/access-keeper'

export default {
  name: 'IndexProviders',
  components: {
    AccessKeeper,
    Layout,
    ProviderHeader,
    ProviderTable,
  },
  methods: {
    handleNewProviderAdded() {
      this.$refs.providerTable.handleUpdateProvider();
    },
  },
};
</script>
