var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "prodivers-list" },
    [
      _c("custom-data-table", {
        ref: "providerDataTable",
        attrs: {
          fields: _vm.fields,
          filters: _vm.filters,
          query: _vm.contract_list_query,
          "query-node": "contracts",
        },
        on: { "vuetable:row-clicked": _vm.$_goTo },
        scopedSlots: _vm._u([
          {
            key: "company_name",
            fn: function (props) {
              return _c("span", {}, [
                _c("strong", [_vm._v(_vm._s(props.rowData.company_name))]),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.setVatFormat(props.rowData.company_vat)) +
                    " "
                ),
              ])
            },
          },
          {
            key: "contract_number",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    props.rowData.contract_number
                      ? props.rowData.contract_number
                      : "--"
                  )
                ),
              ])
            },
          },
          {
            key: "contract_date",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(props.rowData.contract_date, "DD/MM/YYYY")
                  )
                ),
              ])
            },
          },
          {
            key: "volume_mobilized",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getCarsMobilizedCount(props.rowData.orders.items)
                    ) +
                    " "
                ),
              ])
            },
          },
          {
            key: "volume",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(_vm.getCarsCount(props.rowData.orders.items)) +
                    " "
                ),
              ])
            },
          },
          {
            key: "price",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("money")(
                        _vm.getCarsMouthPrice(props.rowData.orders.items)
                      )
                    ) +
                    " "
                ),
              ])
            },
          },
          {
            key: "actions",
            fn: function (props) {
              return _c(
                "span",
                { staticStyle: { display: "inline-flex" } },
                [
                  _vm.canCreateUser
                    ? _c(
                        "b-button",
                        {
                          staticClass: "user-button text-success",
                          attrs: {
                            title: "Adicionar usuário",
                            variant: "link",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openModalAddUserInProvider(
                                props.rowData
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-user-plus" })]
                      )
                    : _vm._e(),
                  _vm.canCreateUser
                    ? _c("div", { staticClass: "m-1" })
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "edit-button",
                      attrs: { title: "Editar contrato", variant: "link" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openModalUpdateProvider(props.rowData)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-edit" })]
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c("modal-provider-add", {
        ref: "modalProviderAdd",
        attrs: { provider: _vm.provider },
        on: {
          close: function ($event) {
            _vm.provider = {}
          },
          success: _vm.handleUpdateProvider,
        },
      }),
      _vm.canCreateUser
        ? _c("modal-user-add", {
            ref: "modalUserAdd",
            attrs: { provider: _vm.provider },
            on: {
              close: function ($event) {
                _vm.provider = {}
              },
              success: _vm.handleUpdateProvider,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }