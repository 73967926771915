<template>
  <div class="prodivers-list">
    <custom-data-table
      ref="providerDataTable"
      :fields="fields"
      :filters="filters"
      :query="contract_list_query"
      query-node="contracts"
      @vuetable:row-clicked="$_goTo"
    >
      <span slot="company_name" slot-scope="props">
        <strong>{{ props.rowData.company_name }}</strong>
        <br>
        {{ setVatFormat(props.rowData.company_vat) }}
      </span>
      <span
        slot="contract_number"
        slot-scope="props"
      >{{ props.rowData.contract_number ? props.rowData.contract_number : '--' }}</span>
      <span
        slot="contract_date"
        slot-scope="props"
      >{{ props.rowData.contract_date | moment('DD/MM/YYYY') }}</span>
      <span slot="volume_mobilized" slot-scope="props">
        {{ getCarsMobilizedCount(props.rowData.orders.items) }}
      </span>
      <span slot="volume" slot-scope="props">
        {{ getCarsCount(props.rowData.orders.items) }}
      </span>
      <span
        slot="price"
        slot-scope="props"
      >
        {{ getCarsMouthPrice(props.rowData.orders.items) | money }}
      </span>
      <span slot="actions" slot-scope="props" style="display: inline-flex">
        <b-button
          v-if="canCreateUser"
          title="Adicionar usuário"
          variant="link"
          class="user-button text-success"
          @click.stop="openModalAddUserInProvider(props.rowData)"
        >
          <i class="fa fa-user-plus" />
        </b-button>
        <div v-if="canCreateUser" class="m-1" />
        <b-button
          title="Editar contrato"
          variant="link"
          class="edit-button"
          @click.stop="openModalUpdateProvider(props.rowData)"
        >
          <i class="fa fa-edit" />
        </b-button>
      </span>
    </custom-data-table>

    <modal-provider-add
      ref="modalProviderAdd"
      :provider="provider"
      @close="provider = {}"
      @success="handleUpdateProvider"
    />
    <modal-user-add
      v-if="canCreateUser"
      ref="modalUserAdd"
      :provider="provider"
      @close="provider = {}"
      @success="handleUpdateProvider"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { formatVat } from '@utils/numbers';
import { formatStatus, countVolume, countVolumeMobilized, countMonthPrice } from '@utils/contract';
import CustomDataTable from '@components/CustomDataTable';
import GET_CONTRACT from '@graphql/contract/queries/get-contract.gql';
import ModalProviderAdd from '@components/modals/provider/add';
import modalUserAdd from '@components/modals/provider/add-user';

export default {
  name: 'ProviderTable',
  components: {
    CustomDataTable,
    ModalProviderAdd,
    modalUserAdd,
  },
  data() {
    return {
      provider: {},
      default_status: ['ACTIVE', 'PENDING', 'CLOSED'],
      contract_list_query: GET_CONTRACT,
      fields: [
        {
          name: 'company_name',
          title: this.$t('providers.header.provider'),
          sortField: 'company_name',
        },
        {
          name: 'contract_number',
          title: this.$t('providers.tables.contract'),
          sortField: 'contract_number',
        },
        {
          name: 'contract_date',
          title: this.$t('providers.tables.date'),
          sortField: 'contract_date',
        },
        {
          name: 'volume_mobilized',
          title: 'Carros Mobilizados',
        },
        {
          name: 'volume',
          title: 'Total Carros',
        },
        {
          name: 'price',
          title: 'Valor mensal total',
        },
        {
          name: 'actions',
          title: '',
        },
      ],
    };
  },
  computed: {
    canCreateUser() {
      return this.$store.getters['user/groups'].includes('ACL:USER_SUPPLY:EDIT')
    },
    filters() {
      return {
        query: {
          type: 'textfield',
          props: {
            label: false,
            placeholder: this.$t('providers.tables.contractSearchPlaceholder'),
          },
          graphqlQuery(value) {
            return {
              $or: [
                {
                  company_name: {
                    $like: `%${value}%`,
                  },
                },
              ],
            };
          },
        },
      };
    },
  },
  mounted() {
    this.setContract(''); //Clear Contract ID
    this.setOrder(''); //Clear Order ID
  },
  methods: {
    ...mapActions({
      setContract: 'provider/setContract',
      setOrder: 'provider/setOrder',
    }),
    setVatFormat(vat) {
      return formatVat(vat);
    },
    $_goTo(_item = null) {
      _item = _item.data;
      this.setContract({
        id: _item.id,
        company: _item.company_name,
      });
      // console.log(_item.id)
      this.$router.push(`/providers/contract/${_item.id}`);
    },
    openModalUpdateProvider(item) {
      this.provider = item;
      this.$refs.modalProviderAdd.$refs.modalProviderAdd.show();
    },
    openModalAddUserInProvider(item) {
      this.provider = item;
      this.$refs.modalUserAdd.$refs.modalUserAdd.show();
    },
    handleUpdateProvider() {
      this.$refs.providerDataTable.$_refresh();
    },
    getCarsCount(cars) {
      return countVolume(cars);
    },
    getCarsMobilizedCount(cars) {
      return countVolumeMobilized(cars);
    },
    getCarsMouthPrice(cars) {
      return countMonthPrice(cars);
    },
    countMonthPrice,
    formatStatus,
  },
};
</script>

<style lang="sass" scoped>
.vuetable-th-slot-actions
  width: 30px
.vuetable-th-slot-status
  width: 150px
.edit-button
  padding: 0
  margin: 0
.badge
  padding: 5px 10px
  color: white
  &-ACTIVE
    background: green
  &-PENDING
    background: orange
  &-CLOSED
    background: red
</style>
