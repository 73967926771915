<template>
  <b-row class="pb-4">
    <b-col cols="6">
      <h2>{{ $t('providers.titles.providers') }}</h2>
    </b-col>
    <b-col cols="6" class="d-flex flex-row-reverse">
      <b-button @click="newContract">
        {{ $t('providers.header.newContract') }}
      </b-button>
      <div class="m-1" />
      <b-button variant="primary" @click="openListUserContract">
        {{ $t('users.titles.listUsers') }}
      </b-button>
    </b-col>

    <modal-provider-add ref="modalProviderAdd" @success="$emit('newProviderAdded')" />
  </b-row>
</template>

<script>
import ModalProviderAdd from '@components/modals/provider/add';

export default {
  name: 'AdminProvidersHeader',
  components: {
    ModalProviderAdd,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    newContract() {
      this.$refs.modalProviderAdd.$refs.modalProviderAdd.show();
    },
    openListUserContract() {
      this.$router.push({
        name: 'users',
      });
    }
  },
};
</script>
