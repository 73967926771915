var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "pb-4" },
    [
      _c("b-col", { attrs: { cols: "6" } }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("providers.titles.providers")))]),
      ]),
      _c(
        "b-col",
        { staticClass: "d-flex flex-row-reverse", attrs: { cols: "6" } },
        [
          _c("b-button", { on: { click: _vm.newContract } }, [
            _vm._v(" " + _vm._s(_vm.$t("providers.header.newContract")) + " "),
          ]),
          _c("div", { staticClass: "m-1" }),
          _c(
            "b-button",
            {
              attrs: { variant: "primary" },
              on: { click: _vm.openListUserContract },
            },
            [_vm._v(" " + _vm._s(_vm.$t("users.titles.listUsers")) + " ")]
          ),
        ],
        1
      ),
      _c("modal-provider-add", {
        ref: "modalProviderAdd",
        on: {
          success: function ($event) {
            return _vm.$emit("newProviderAdded")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }