var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "access-keeper",
        { attrs: { entity: "PROVIDER" } },
        [
          _c("provider-header", {
            on: { newProviderAdded: _vm.handleNewProviderAdded },
          }),
          _c("provider-table", { ref: "providerTable" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }